<template>
  <div class="subscribeC">
    <Alert :title="this.title" :msg="this.msg" />
    <AlertSuccess :title="this.title" :msg="this.msg" />
    <AlertPreview :title="this.title" :src="this.src" />
    <AlertSubscribe :email="this.email" />
    <AlertUnsubscribe :email="this.email" />

    <div class="container mt-content">
      <div class="row">
        <div class="col-md-6 col-12 text-center">
          <img class="img-fluid" src="../assets/png/enews-group.png" />
          <!--<p class="landing-des">
            บริการจดหมายข่าวทางอีเมลจาก Thai PBS ในรูปแบบออนไลน์ บริการสรุปข่าว
            แจ้งรายการเด่น กิจกรรมดี ๆ จากไทยพีบีเอสผ่านทางอีเมลส่งตรงให้แฟน ๆ
            ได้อ่านสะดวก ดูสบายตา เลือกรับบริการทั้ง 3
            รูปแบบตามไลฟ์สไตล์ของคุณได้แล้ววันนี้
          </p>-->
        </div>
        <div class="col-md-6 col-12">
          <div class="form-card text-start">
            <div class="h2-bold">อัพเดทการรับข้อมูลจดหมายข่าวฟรี</div>
            <div class="input-group mb-2">
              <label class="w-100">
                อีเมล <strong class="text-danger">*</strong>
                <input
                  type="email"
                  class="form-control"
                  style="font-size: 24px"
                  aria-label="กรอก Email ของท่าน"
                  v-model="this.email2"
                  v-on:keyup="triggerSearch"
                />
              </label>
              <small
                id="inputRequired2"
                class="text-danger"
                :hidden="!inputRequired[2]"
              >
                กรุณากรอกอีเมลของท่าน
              </small>
              <small
                id="invalidEmail"
                class="text-danger"
                :hidden="!invalidEmail"
              >
                รูปแบบอีเมลของท่านไม่ถูกต้อง
              </small>
            </div>
            <div class="h2-bold mt-4">เลือกจดหมายข่าวที่ต้องการติดตาม</div>
            <small
              id="inputRequired0"
              class="text-danger"
              :hidden="!inputRequired[0]"
            >
              กรุณาเลือกรับข่าวสารอย่างน้อย 1 รายการ
            </small>
            <div class="form-check-group">
              <div class="form-check mt-3">
                <input
                  v-model="this.type2[0]"
                  v-on:change="updateCheckboxes(true)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault0"
                  :disabled="!this.ready"
                />
                <label class="form-check-label" for="flexCheckDefault0">
                  <strong
                    >Thai PBS Daily News Digest (ทุกวัน เวลา 15.00 น.)</strong
                  >
                  <br />
                  <span class="text-muted"
                    >คัดข่าวเด่นและสถานการณ์สำคัญในรอบวัน
                    ให้คุณไม่พลาดทั้งในไทยและทั่วโลก</span
                  >
                  <br />
                  <span class="text-muted"
                    >(
                    <a
                      href="#"
                      v-on:click="alertPreview(this.previews[0])"
                      type="button"
                      >ดูตัวอย่างจดหมายข่าว</a
                    >
                    )</span
                  >
                </label>
              </div>
              <div class="form-check mt-3">
                <input
                  v-model="this.type2[1]"
                  v-on:change="updateCheckboxes(true)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault1"
                  :disabled="!this.ready"
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  <strong>Thai PBS Catch up (1 ครั้ง / เดือน)</strong>
                  <br />
                  <span class="text-muted"
                    >คัดสรรคลิปดี ให้ชมฟรีกับวิดีโอยอดนิยมประจำเดือน</span
                  >
                  <br />
                  <span class="text-muted"
                    >(
                    <a
                      href="#"
                      v-on:click="alertPreview(this.previews[1])"
                      type="button"
                      >ดูตัวอย่างจดหมายข่าว</a
                    >
                    )</span
                  >
                </label>
              </div>
              <div class="form-check mt-3">
                <input
                  v-model="this.type2[2]"
                  v-on:change="updateCheckboxes(true)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault2"
                  :disabled="!this.ready"
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  <strong>Thai PBS Highlight (1 ครั้ง / เดือน)</strong>
                  <br />
                  <span class="text-muted"
                    >แจ้งล่วงหน้ากับรายการเด่น กิจกรรมและแคมเปญดี ๆ เพื่อแฟน ๆ
                    ไทยพีบีเอส</span
                  >
                  <br />
                  <span class="text-muted"
                    >(
                    <a
                      href="#"
                      v-on:click="alertPreview(this.previews[2])"
                      type="button"
                      >ดูตัวอย่างจดหมายข่าว</a
                    >
                    )</span
                  >
                </label>
              </div>
            </div>

            <div class="mt-4 text-center">
              <button
                :hidden="!isLoading || !ready"
                class="btn btn-lg btn-danger"
                type="button"
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                กำลังดำเนินการ...
              </button>
              <button
                :hidden="isLoading || !ready"
                v-on:click="submitForm"
                type="button"
                class="btn-orange"
              >
                อัพเดทข้อมูลของท่าน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import AlertSuccess from "@/components/AlertSuccess.vue";
import AlertPreview from "@/components/AlertPreview.vue";
import AlertSubscribe from "@/components/AlertSubscribe.vue";
import AlertUnsubscribe from "@/components/AlertUnsubscribe.vue";
import sendy from "../assets/js/sendy";

export default {
  name: "EditSubscribeC",
  components: {
    Alert,
    AlertSuccess,
    AlertPreview,
    AlertSubscribe,
    AlertUnsubscribe,
  },
  data() {
    return {
      ready: false,
      title: null,
      msg: null,
      all: true,
      invalidEmail: false,
      inputRequired: [false, false, false, false],
      type: [false, false, false],
      type2: [false, false, false],
      isLoading: false,
      email: this.$route.query.email,
      email2: "",
      name: null,
      agreeTerms: false,
      previews: [
        {
          title: "ตัวอย่าง - Thai PBS Daily News Digest (ทุกวัน เวลา 15.00 น.)",
          src: 2,
        },
        {
          title: "ตัวอย่าง - Thai PBS Catch up (1 ครั้ง / เดือน)",
          src: 1,
        },
        {
          title: "ตัวอย่าง - Thai PBS Highlight (1 ครั้ง / เดือน)",
          src: 0,
        },
      ],
    };
  },
  mounted: function () {
    this.init();
  },
  constructor: function () {},
  methods: {
    init: function () {
      this.email2 = this.email;
      if (this.email) {
        sendy
          .searchSubscriber(this.email)
          .then((success) => {
            if (success == sendy.response_code.NOT_SUBSCRIBER) {
              this.alertSubscribe();
            } else {
              //LOOKS LIKE YOU ARE NOT MEMBER YET, SUBSCRIBE NOW!
              this.ready = true;
              console.log(success);
              this.type[0] = success[0];
              this.type[1] = success[1];
              this.type[2] = success[2];
              this.type2[0] = success[0];
              this.type2[1] = success[1];
              this.type2[2] = success[2];
            }
          })
          .catch((err) => {
            console.log(err);
            //this.alertError('เกิดข้อผิดพลาด', 'การเชื่อมต่อเกิดการขัดข้อง กรุณาลองใหม่อีกครั้ง');
            this.ready = false;
          });
      } else {
        console.log("NOT READY");
        this.ready = false;
      }
    },
    submitForm: async function () {
      //this.isLoading = true;
      this.invalidEmail = false;
      this.isLoading = false;
      this.inputRequired.forEach((v, i) => {
        this.inputRequired[i] = false;
      });

      console.log(this.email, this.name, this.type);
      this.validateForm();

      //this.isLoading = false;
    },
    updateCheckboxes: function (isType) {
      if (isType) {
        if (this.type2[0] && this.type2[1] && this.type2[2]) {
          this.all = true;
        }

        if (!this.type2[0] && !this.type2[1] && !this.type2[2]) {
          this.all = false;
        }
      } else {
        if (this.all) {
          this.type2[0] = true;
          this.type2[1] = true;
          this.type2[2] = true;
        } else {
          this.type2[0] = false;
          this.type2[1] = false;
          this.type2[2] = false;
        }
      }
    },
    validateForm: function () {
      if (this.email2) {
        if (this.validateEmail(this.email2)) {
          //VALID EMAIL
          let ok = false;
          this.type2.forEach((t) => {
            //ATLEAST ONE BOX MUST BE TICKED
            if (t) ok = true;
          });
          this.invalidEmail = false;
          if (ok) {
            //PASSED
            this.name = this.email2.split("@")[0];
            this.isLoading = true;
            if (this.email == this.email2) {
              //STILL USE SAME EMAIL
              sendy
                .unsubscribeEach(this.email, this.type)
                .then(() => {
                  sendy
                    .subscribeEach(
                      this.name,
                      this.email2,
                      this.type2,
                      "",
                      "",
                      ""
                    )
                    .then(() => {
                      console.log(this.type2);
                      this.isLoading = false;
                      this.email = this.email2;
                      this.type = this.type2;
                      this.alertSuccess(
                        "สำเร็จ",
                        "เราอัพเดทข้อมูลของท่านสำเร็จแล้ว"
                      );
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                      this.alertError(
                        "เกิดข้อผิดพลาด",
                        "การเชื่อมต่อขัดข้อง กรุณาลองใหม่อีกครั้ง"
                      );
                    });
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                  this.alertError(
                    "เกิดข้อผิดพลาด",
                    "การเชื่อมต่อขัดข้อง กรุณาลองใหม่อีกครั้ง"
                  );
                });
            } else {
              //CHANGE EMAIL ALSO (MUST DELETE AND SUBSCRIBE AS NEW ONE)
              sendy
                .searchSubscriber(this.email2)
                .then((status) => {
                  if (status == sendy.response_code.NOT_SUBSCRIBER) {
                    sendy
                      .deleteSubscriberEach(this.email, this.type) //USE ORIGINAL EMAIL FIRST
                      .then(() => {
                        sendy
                          .subscribeEach(
                            this.name,
                            this.email2,
                            this.type2,
                            "",
                            "",
                            ""
                          )
                          .then(() => {
                            this.isLoading = false;
                            this.email = this.email2;
                            this.type = this.type2;
                            this.alertSuccess(
                              "สำเร็จ",
                              "เราอัพเดทข้อมูลของท่านสำเร็จแล้ว"
                            );
                          })
                          .catch((err) => {
                            this.isLoading = false;
                            console.log(err);
                            this.alertError(
                              "เกิดข้อผิดพลาด",
                              "การเชื่อมต่อขัดข้อง กรุณาลองใหม่อีกครั้ง"
                            );
                          });
                      })
                      .catch((err) => {
                        this.isLoading = false;
                        console.log(err);
                        this.alertError(
                          "เกิดข้อผิดพลาด",
                          "การเชื่อมต่อขัดข้อง กรุณาลองใหม่อีกครั้ง"
                        );
                      });
                  } else {
                    this.isLoading = false;
                    this.alertError(
                      "คำเตือน!",
                      "อีเมลดังกล่าว มีการสมัครสมาชิกไว้แล้ว กรุณาใช้อีเมลอื่น"
                    );
                  }
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                  this.alertError(
                    "เกิดข้อผิดพลาด",
                    "การเชื่อมต่อขัดข้อง กรุณาลองใหม่อีกครั้ง"
                  );
                });
            }
          } else {
            //WARNING YOU ARE ABOUT TO UNSUBSCRIBE
            this.isLoading = false;
            this.alertUnsubscribe();
          }
        } else {
          //INVALID EMAIL
          this.isLoading = false;
          this.invalidEmail = true;
        }
      } else {
        this.isLoading = false;
        this.inputRequired[2] = true;
        window.location.href = "#inputRequired2";
      }
    },
    validateEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    triggerSearch: function () {
      this.invalidEmail = false;
      this.email = this.email2;

      if (this.validateEmail(this.email)) {
        console.log(this.email);
        sendy
          .searchSubscriber(this.email)
          .then((success) => {
            if (success == sendy.response_code.NOT_SUBSCRIBER) {
              //this.alertSubscribe();
              this.ready = false;
              this.type[0] = false;
              this.type[1] = false;
              this.type[2] = false;
              this.type2[0] = false;
              this.type2[1] = false;
              this.type2[2] = false;
            } else {
              //LOOKS LIKE YOU ARE NOT MEMBER YET, SUBSCRIBE NOW!
              this.ready = true;
              this.type[0] = success[0];
              this.type[1] = success[1];
              this.type[2] = success[2];
              this.type2[0] = success[0];
              this.type2[1] = success[1];
              this.type2[2] = success[2];
            }
          })
          .catch((err) => {
            console.log(err);
            //this.alertError('เกิดข้อผิดพลาด', 'การเชื่อมต่อเกิดการขัดข้อง กรุณาลองใหม่อีกครั้ง');
            this.ready = false;
          });
      } else {
        this.invalidEmail = true;
        this.ready = false;
      }
    },
    checkEmailFormat: function () {
      if (this.validateEmail(this.email)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
    alertError: function (title, msg) {
      this.title = title;
      this.msg = msg;
      document.querySelector("#btnAlertError").click();
    },
    alertSuccess: function (title, msg) {
      this.title = title;
      this.msg = msg;
      document.querySelector("#btnAlertSuccess").click();
    },
    alertEditSubscribe: function () {
      document.querySelector("#btnAlertEditSubscribe").click();
    },
    alertPreview: function (prv) {
      this.title = prv.title;
      this.src = prv.src;
      document.querySelector("#btnAlertPreview").click();
    },
    alertSubscribe: function () {
      document.querySelector("#btnAlertSubscribe").click();
    },
    alertUnsubscribe: function () {
      document.querySelector("#btnAlertUnsubscribe").click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #fe5000;
}
</style>
