<template>
  <!-- Vertically centered scrollable modal -->
  <div
    class="
      modal
      fade
    "
    id="AlertPreview"
    tabindex="-1"
    aria-labelledby="AlertPreviewLabel"
    aria-hidden="true"
  >
      <button
      hidden="true"
      id="btnAlertPreview"
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#AlertPreview"
    >
      btn_alert_preview
    </button>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="AlertPreviewLabel">
            {{title}}
            </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img v-if="this.src == 0" style="width: 100%; height: auto;" src="../assets/preview/preview1.jpg">
          <img v-if="this.src == 1" style="width: 100%; height: auto;" src="../assets/preview/preview2.jpg">
          <img v-if="this.src == 2" style="width: 100%; height: auto;" src="../assets/preview/preview3.png">
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-lg btn-secondary"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertPreview",
  props: {
      title: String,
      src: Number
  },
  constructor: function () {},
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
