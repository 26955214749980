<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg navbar-dark bg-orange bg-tpbs" style="padding-top: 0px; padding-bottom: 0px;">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img
            src="./assets/tpbs.png"
            alt=""
            class="ms-4 d-inline-block align-text-top"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link text-white" href="https://news.thaipbs.or.th/"
                >ข่าว</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white"
                href="https://program.thaipbs.or.th/"
                >รายการทีวี</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white"
                href="https://www.thaipbs.or.th/live"
                >ชมสด</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white"
                href="https://www.thaipbs.or.th/watch"
                >วิดีโอ</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white"
                href="https://program.thaipbs.or.th/playlists"
                >เพลย์ลิสต์</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-white"
                href="https://www.thaipbspodcast.com/radio/"
                >วิทยุ</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="https://org.thaipbs.or.th/"
                >องค์กร</a
              >
            </li>
          </ul>
          <div class="d-flex">
            <a
              href="https://facebook.com/ThaiPBS"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/facebook-brands.svg" />
            </a>
            <a
              href="https://twitter.com/ThaiPBS"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/twitter-brands.svg" />
            </a>
            <a
              href="https://www.youtube.com/user/ThaiPBS"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/youtube-brands.svg" />
            </a>
            <a
              href="https://instagram.com/thaipbs"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/instagram-brands.svg" />
            </a>
            <a
              href="https://line.me/ti/p/@ThaiPBS"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/line-brands.svg" />
            </a>
            <a
              href="https://www.tiktok.com/@thaipbs"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/tiktok-brands.svg" />
            </a>
            <a
              href="https://program.thaipbs.or.th/xml/home"
              target="_blank"
              class="btn-clear-light"
            >
              <img src="./assets/svg/rss-solid.svg" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div id="landing">
    <div>
      <h1>
        <strong>
        <span class="text-white">Thai PBS</span>
        <span class="text-white"> Newsletters</span>
        </strong>
      </h1>
    </div>
  </div>
  <router-view />
  <footer class="bg-orange">
    <div class="row ms-3 pt-3 pb-3 me-3">
      <div class="col-md-12 col-sm-12 text-start">
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="https://thaipbs.or.th/sitemap">แผนผังเว็บไซต์</a>
          </div>
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="https://org.thaipbs.or.th/service/faq">คำถามที่พบบ่อย</a>
          </div>
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="https://org.thaipbs.or.th/announce/policy">นโยบายส่วนบุคคล</a>
          </div>
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="https://org.thaipbs.or.th/career/vacantposition">ร่วมงานกับเรา</a>
          </div>
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="https://org.thaipbs.or.th/other/termsandconditions">ข้อกำหนดและเงื่อนไข</a>
          </div>
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="mailto:webmaster@thaipbs.or.th">ติดต่อเว็บมาสเตอร์</a>
          </div>
          <div class="d-inline">
            <a class="btn btn-sm text-white" href="https://thaipbs.or.th/complaint">รับเรื่องร้องเรียนจริยธรรม</a>
          </div>
        <p class="line-space"></p>
        <span class="text-medium">
          <small>
          © 2021 องค์การกระจายเสียงและแพร่ภาพสาธารณะแห่งประเทศไทย
          </small>
        </span>
      </div>
    </div>
  </footer>
</template>


<style scope>
@import "assets/css/main.css";
</style>