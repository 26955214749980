<template>
  <div class="subscribeV">
     <Subscribe  v-if="method == 'subscribe'"/>
     <EditSubscribe  v-if="method == 'edit-subscribe'"/>
     <Unsubscribe  v-if="method == 'unsubscribe'"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Subscribe from '@/components/Subscribe.vue'
import Unsubscribe from '@/components/Unsubscribe.vue'
import EditSubscribe from '@/components/EditSubscribe.vue'

export default {
  name: 'subscribeV',
  data: function(){
    return {
      method: this.$route.query.method || 'subscribe'
    }
  },
  components: {
    Subscribe,
    Unsubscribe,
    EditSubscribe
  },
  created: function(){
    console.log(this.method)
  }
  }
</script>
