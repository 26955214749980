<template>
  <!-- Vertically centered scrollable modal -->
  <div
    class="modal fade"
    id="AlertUnsubscribe"
    tabindex="-1"
    aria-labelledby="AlertUnsubscribeLabel"
    aria-hidden="true"
  >
      <button
      hidden="true"
      id="btnAlertUnsubscribe"
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#AlertUnsubscribe"
    >
      btn_alert_unsubscribe
    </button>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
        />
      </symbol>
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        />
      </symbol>
      <symbol
        id="exclamation-triangle-fill"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
        />
      </symbol>
    </svg>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-danger" id="AlertUnsubscribeLabel">
            <svg
              class="bi flex-shrink-0 me-2"
              width="24"
              height="24"
              role="img"
              aria-label="Warning:"
            >
              <use xlink:href="#exclamation-triangle-fill" />
            </svg>
            คำเตือน! ท่านกำลังยกเลิกการเป็นสมาชิก
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div :hidden="isUnsubscribed" class="text-start">
            <h4>
              ท่านช่วยบอกเหตุผลกับทางเราหน่อยได้ไหม
              เพื่อเราจะได้ปรับปรุงและพัฒนาต่อไป
            </h4>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="reason"
                id="reason1"
                value="ฉันไม่ต้องการรับจดหมายข่าวนี้แล้ว"
                v-model="this.reason"
                @change="updateRadio"
                :disabled="isLoading"
              />
              <label class="form-check-label text-24" for="reason1">
                ฉันไม่ต้องการรับจดหมายข่าวนี้แล้ว
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="reason"
                id="reason2"
                value="ฉันไม่เคยสมัครรับจดหมายข่าวนี้มาก่อน"
                v-model="this.reason"
                @change="updateRadio"
              />
              <label class="form-check-label text-24" for="reason2">
                ฉันไม่เคยสมัครรับจดหมายข่าวนี้มาก่อน
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="reason"
                id="reason3"
                value="อีเมลที่ได้รับไม่ตรงตามความต้องการของฉัน"
                v-model="this.reason"
                @change="updateRadio"
                :disabled="isLoading"
              />
              <label class="form-check-label text-24" for="reason3">
                อีเมลที่ได้รับไม่ตรงตามความต้องการของฉัน
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="reason"
                id="reason4"
                value="other"
                v-model="this.reason"
                @change="updateRadio"
                :disabled="isLoading"
              />
              <label class="form-check-label text-24" for="reason4">
                อี่นๆ
              </label>
            </div>

            <h3 :hidden="!isOther" class="mt-4">รบกวนแจ้งเหตุผลให้เราทราบ</h3>
            <div :hidden="!isOther" class="input-group">
              <textarea
                class="form-control"
                 style="font-size: 22px;"
                aria-label="With textarea"
                v-model="this.remark"
                :disabled="isLoading"
              ></textarea>
            </div>
          </div>
          <div :hidden="!isUnsubscribed">
            <!--<img class="text-center" style="width: 128px; height: 128px;" src="../assets/png/sad.png">-->
            <h2>
              เรารู้สึกเสียใจเป็นอย่างยิ่ง
              <br>
              ขอขอบคุณที่ท่านเคยได้รับข่าวสารดีๆจากทางเรามาตลอด
              <br>
              แต่ถ้าหากท่านสนใจที่จะสมัครรับข่าวสารอีกครั้ง สามารถไปที่เว็บ 
              <br>
              <a target="_blank" href="https://enews.thaipbs.dev/">( enews.thaipbs.dev )</a>
            </h2>
          </div>
        </div>
        <div :hidden="isUnsubscribed" class="modal-footer">
          <button
            v-on:click="this.unsubscribeAll"
            type="button"
            class="btn btn-lg btn-danger"
            :hidden="isLoading"
          >
            ดำเนินการต่อ
          </button>
                    <button
            type="button"
            class="btn btn-lg btn-secondary"
            data-bs-dismiss="modal"
            :hidden="isLoading"
          >
            ปิด
          </button>
          <button :hidden="!isLoading" class="btn btn-lg btn-danger" type="button">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            กำลังดำเนินการ...
          </button>
        </div>
        <div :hidden="!isUnsubscribed" class="modal-footer">
          <button
            type="button"
            class="btn btn-lg btn-secondary"
            data-bs-dismiss="modal"
            v-on:click="goToHome"
          >
            กลับสู่หน้หลัก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sendy from "../assets/js/sendy";

export default {
  name: "AlertUnsubscribe",
  props: {
    email: String,
  },
  data() {
    return {
      reason: null,
      remark: null,
      isOther: false,
      isLoading: false,
      isUnsubscribed: false
    };
  },
  constructor: function () {},
  mounted() {
    console.log(this.email);
  },
  methods: {
    unsubscribeAll: function () {
      this.isLoading = true;
      sendy
        .unsubscribeEach(this.email, [true, true, true])
        .then(() => {
          this.isLoading = false;
          //SAY THENK YOU AND GOODBYE
          this.isUnsubscribed = true;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          //ERROR WITH CONNECTION
        });
    },
    updateRadio: function () {
      if (this.reason === "other") {
        this.isOther = true;
        console.log(this.reason);
      } else {
        this.isOther = false;
      }
    },
    goToHome: function(){
      window.location.href = "/"
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
