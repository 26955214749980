<template>
  <div class="UnsubscribeV">
    <Unsubscribe/>
  </div>
</template>

<script>
// @ is an alias to /src
import Unsubscribe from '@/components/Unsubscribe.vue'

export default {
  name: 'UnsubscribeV',
  components: {
    Unsubscribe
  }
}
</script>
