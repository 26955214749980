<template>
  <div class="redeemSubscribeV">
    <RedeemSubscribe/>
  </div>
</template>

<script>
// @ is an alias to /src
import RedeemSubscribe from '@/components/RedeemSubscribe.vue'

export default {
  name: 'redeemSubscribeV',
  components: {
    RedeemSubscribe
  }
}
</script>
