<template>
  <div class="subscribeC">
    <Alert :title="this.title" :msg="this.msg" />
    <AlertEditSubscribe :email="this.email" />
    <AlertSuccess :title="this.title" :msg="this.msg" />
    <AlertPreview :title="this.title" :src="this.src" />
    <div class="container mt-content">
      <div class="row">
        <div class="col-md-6 col-12 text-center">
          <img class="img-fluid" src="../assets/png/enews-group.png" />
          <!--<p class="landing-des">
            บริการจดหมายข่าวทางอีเมลจาก Thai PBS ในรูปแบบออนไลน์ บริการสรุปข่าว
            แจ้งรายการเด่น กิจกรรมดี ๆ จากไทยพีบีเอสผ่านทางอีเมลส่งตรงให้แฟน ๆ
            ได้อ่านสะดวก ดูสบายตา เลือกรับบริการทั้ง 3
            รูปแบบตามไลฟ์สไตล์ของคุณได้แล้ววันนี้
          </p>-->
        </div>
        <div class="col-md-6 col-12">
          <div class="form-card text-start">
            <div
              id="isDone"
              class="text-center"
              style="height: 400px"
              :hidden="!isDone"
            >
              <p class="h2" style="margin-top: 96px">
                <img
                  class="text-center"
                  style="width: 128px; height: 128px; margin-bottom: 16px"
                  src="../assets/png/cheering.png"
                />
                <br />
                ขอบคุณที่ท่านรับสมัครจดหมายข่าวกับไทยพีบีเอส
                <br />
                หากต้องการความช่วยเหลือ หรือ เสนอแนะการให้บริการ
                <br />
                ติดต่อได้ที่
                <a href="mailto:webmaster@thaipbs.or.th"
                  >webmaster@thaipbs.or.th</a
                >
                <br />
                หรือทุกช่องทางออนไลน์ของ Thai PBS
              </p>
            </div>
            <div :hidden="isDone">
              <div class="h2-bold">เลือกจดหมายข่าวที่ต้องการติดตาม</div>
              <small
                id="inputRequired0"
                class="text-danger"
                :hidden="!inputRequired[0]"
              >
                กรุณาเลือกรับข่าวสารอย่างน้อย 1 รายการ
              </small>
              <div class="form-check-group">
                <div class="form-check mt-3">
                  <input
                    v-model="this.type[0]"
                    v-on:change="updateCheckboxes(true)"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault0"
                  />
                  <label class="form-check-label" for="flexCheckDefault0">
                    <strong
                      >Thai PBS Daily News Digest (ทุกวัน เวลา 15.00 น.)</strong
                    >
                    <br />
                    <span class="text-muted"
                      >คัดข่าวเด่นและสถานการณ์สำคัญในรอบวัน
                      ให้คุณไม่พลาดทั้งในไทยและทั่วโลก</span
                    >
                    <br />
                    <span class="text-muted"
                      >(
                      <a
                        href="#"
                        v-on:click="alertPreview(this.previews[0])"
                        type="button"
                        >ดูตัวอย่างจดหมายข่าว</a
                      >
                      )</span
                    >
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input
                    v-model="this.type[1]"
                    v-on:change="updateCheckboxes(true)"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault1"
                  />
                  <label class="form-check-label" for="flexCheckDefault1">
                    <strong>Thai PBS Catch up (1 ครั้ง / เดือน)</strong>
                    <br />
                    <span class="text-muted"
                      >คัดสรรคลิปดี ให้ชมฟรีกับวิดีโอยอดนิยมประจำเดือน</span
                    >
                    <br />
                    <span class="text-muted"
                      >(
                      <a
                        href="#"
                        v-on:click="alertPreview(this.previews[1])"
                        type="button"
                        >ดูตัวอย่างจดหมายข่าว</a
                      >
                      )</span
                    >
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input
                    v-model="this.type[2]"
                    v-on:change="updateCheckboxes(true)"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault2"
                  />
                  <label class="form-check-label" for="flexCheckDefault2">
                    <strong>Thai PBS Highlight (1 ครั้ง / เดือน)</strong>
                    <br />
                    <span class="text-muted"
                      >แจ้งล่วงหน้ากับรายการเด่น กิจกรรมและแคมเปญดี ๆ เพื่อแฟน ๆ
                      ไทยพีบีเอส</span
                    >
                    <br />
                    <span class="text-muted"
                      >(
                      <a
                        href="#"
                        v-on:click="alertPreview(this.previews[2])"
                        type="button"
                        >ดูตัวอย่างจดหมายข่าว</a
                      >
                      )</span
                    >
                  </label>
                </div>
              </div>
              <div class="h2-bold mt-4">อัพเดทการรับข้อมูลจดหมายข่าวได้ฟรี</div>
              <!--<div class="input-group mb-2">
              <span
                :hidden="!inputRequired[1]"
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                กรุณากรอกชื่อ-นามสกุลของท่าน
              </span>
              <label class="w-100">
                ชื่อ-นามสกุล
                <input
                  type="text"
                  class="form-control"
                  style="font-size: 24px"
                  placeholder="กรอกชื่อจริง-นามสกุล ของท่าน"
                  aria-label="กรอกชื่อจริง-นามสกุล ของท่าน"
                  v-model="this.name"
                  required
                />
              </label>
            </div>-->
              <div class="input-group mb-2">
                <label class="w-100">
                  อีเมล <strong class="text-danger">*</strong>
                  <input
                    type="email"
                    class="form-control"
                    style="font-size: 24px"
                    aria-label="กรอก Email ของท่าน"
                    v-model="this.email"
                    v-on:keyup="checkEmailFormat"
                    required
                  />
                </label>
                <small
                  id="inputRequired2"
                  class="text-danger"
                  :hidden="!inputRequired[2]"
                >
                  กรุณากรอกอีเมลของท่าน
                </small>
                <small
                  id="invalidEmail"
                  class="text-danger"
                  :hidden="!invalidEmail"
                >
                  รูปแบบอีเมลของท่านไม่ถูกต้อง
                </small>
              </div>

              <div class="mt-4 text-center">
                <button
                  :hidden="!isLoading"
                  class="btn btn-lg btn-danger"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  กำลังดำเนินการ...
                </button>
                <button
                  :hidden="isLoading"
                  v-on:click="submitForm"
                  type="button"
                  class="btn-orange"
                >
                  อัพเดทข้อมูลของท่าน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import AlertEditSubscribe from "@/components/AlertEditSubscribe.vue";
import AlertSuccess from "@/components/AlertSuccess.vue";
import AlertPreview from "@/components/AlertPreview.vue";
import sendy from "../assets/js/sendy";

export default {
  name: "RedeemSubscribeC",
  components: {
    Alert,
    AlertEditSubscribe,
    AlertSuccess,
    AlertPreview,
  },
  data() {
    return {
      title: null,
      msg: null,
      all: true,
      invalidEmail: false,
      inputRequired: [false, false, false, false],
      type: [true, true, true],
      isLoading: false,
      isDone: false,
      email: this.$route.query.email,
      name: null,
      agreeTerms: false,
      previews: [
        {
          title: "ตัวอย่าง - Thai PBS Daily News Digest (ทุกวัน เวลา 15.00 น.)",
          src: 2,
        },
        {
          title: "ตัวอย่าง - Thai PBS Catch up (1 ครั้ง / เดือน)",
          src: 1,
        },
        {
          title: "ตัวอย่าง - Thai PBS Highlight (1 ครั้ง / เดือน)",
          src: 0,
        },
      ],
    };
  },
  constructor: function () {},
  methods: {
    submitForm: async function () {
      //this.isLoading = true;
      this.invalidEmail = false;
      this.isLoading = false;
      this.inputRequired.forEach((v, i) => {
        this.inputRequired[i] = false;
      });

      console.log(this.email, this.name, this.type);
      this.validateForm();

      //this.isLoading = false;
    },
    updateCheckboxes: function (isType) {
      if (isType) {
        if (this.type[0] && this.type[1] && this.type[2]) {
          this.all = true;
        }

        if (!this.type[0] && !this.type[1] && !this.type[2]) {
          this.all = false;
        }
      } else {
        if (this.all) {
          this.type[0] = true;
          this.type[1] = true;
          this.type[2] = true;
        } else {
          this.type[0] = false;
          this.type[1] = false;
          this.type[2] = false;
        }
      }
    },
    validateForm: function () {
      if (this.email) {
        if (this.validateEmail(this.email)) {
          let ok = false;
          this.type.forEach((t) => {
            if (t) ok = true;
          });
          this.invalidEmail = false;
          if (ok) {
            //PASSED
            this.name = this.email.split("@")[0];
            this.isLoading = true;
            sendy
              .searchSubscriber(this.email)
              .then((success) => {
                console.log("STAT", success);
                if (success === sendy.response_code.NOT_SUBSCRIBER) {
                  sendy
                    .subscribeEach(this.name, this.email, this.type, "", "", "")
                    .then(() => {
                      this.isLoading = false;
                      this.isDone = true
                      window.location.href = "#isDone"
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      console.log(err);
                      this.alertError(
                        "เกิดข้อผิดพลาด",
                        "ดูเหมือนว่าการเชื่อมต่อจะขัดข้องบางประการ กรุณาลองใหม่อีกครั้ง!"
                      );
                    });
                } else {
                  this.isLoading = false;
                  this.alertEditSubscribe();
                }
              })
              .catch((err) => {
                this.isLoading = false;
                console.log(err);
                this.alertError(
                  "เกิดข้อผิดพลาด",
                  "ดูเหมือนว่าการเชื่อมต่อจะขัดข้องบางประการ กรุณาลองใหม่อีกครั้ง!"
                );
              });
          } else {
            //PLEASE TICK ATLEAST ONE
            this.isLoading = false;
            this.inputRequired[0] = true;
          }
        } else {
          //INVALID EMAIL
          this.isLoading = false;
          this.invalidEmail = true;
        }
      } else {
        if (!this.name) {
          this.isLoading = false;
          this.inputRequired[1] = true;
        }
        if (!this.email) {
          this.isLoading = false;
          this.inputRequired[2] = true;
        }
        if (!this.agreeTerms) {
          this.isLoading = false;
          this.inputRequired[3] = true;
        }
      }
    },
    checkEmailFormat: function () {
      if (this.validateEmail(this.email)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
    validateEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    alertError: function (title, msg) {
      this.title = title;
      this.msg = msg;
      document.querySelector("#btnAlertError").click();
    },
    alertSuccess: function (title, msg) {
      this.title = title;
      this.msg = msg;
      document.querySelector("#btnAlertSuccess").click();
    },
    alertEditSubscribe: function () {
      document.querySelector("#btnAlertEditSubscribe").click();
    },
    alertPreview: function (prv) {
      this.title = prv.title;
      this.src = prv.src;
      document.querySelector("#btnAlertPreview").click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #fe5000;
}
</style>
