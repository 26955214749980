import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import Subscribe from '../views/Subscribe.vue'
import EditSubscribe from '../views/EditSubscribe.vue'
import Unsubscribe from '../views/Unsubscribe.vue'
import RedeemSubscribe from '../views/RedeemSubscribe.vue'

const routes = [
  {
    path: '',
    name: 'Subscibe',
    component: Subscribe
  },
  /*{
    path: '',
    name: 'EditSubscribe',
    component: EditSubscribe
  },*/
  /*{
    path: '',
    name: 'Unsubscribe',
    component: Unsubscribe
  },*/
  {
    path: '/:method/unsubscribe',
    name: 'UnsubscribeCatchup',
    component: Unsubscribe
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
  },
  {
    path: '/edit-subscribe',
    name: 'EditSubscribe',
    component: EditSubscribe
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: Unsubscribe
  },
  {
    path: '/redeem-subscribe',
    name: 'RedeemSubscribe',
    component: RedeemSubscribe
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
