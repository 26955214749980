<template>
  <div class="editSubscribeV">
    <EditSubscribe/>
  </div>
</template>

<script>
// @ is an alias to /src
import EditSubscribe from '@/components/EditSubscribe.vue'

export default {
  name: 'editSubscribeV',
  components: {
    EditSubscribe
  }
}
</script>
