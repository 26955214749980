<template>
  <div class="unsubscribeC">
    <Alert :title="this.title" :msg="this.msg" />
    <AlertSuccess :title="this.title" :msg="this.msg" />
    <AlertPreview :title="this.title" :src="this.src" />
    <AlertSubscribe :email="this.email" />
    <AlertCondolence />

    <div class="container mt-content">
      <div class="row">
        <div :hidden="deadLock" class="col-md-6 col-12 text-center">
          <img class="img-fluid" src="../assets/png/enews-group.png" />
          <!--<p class="landing-des">
            บริการจดหมายข่าวทางอีเมลจาก Thai PBS ในรูปแบบออนไลน์ บริการสรุปข่าว
            แจ้งรายการเด่น กิจกรรมดี ๆ จากไทยพีบีเอสผ่านทางอีเมลส่งตรงให้แฟน ๆ
            ได้อ่านสะดวก ดูสบายตา เลือกรับบริการทั้ง 3
            รูปแบบตามไลฟ์สไตล์ของคุณได้แล้ววันนี้
          </p>-->
        </div>
        <div :hidden="!deadLock" class="col-md-6 col-12 text-center">
          <img class="img-fluid" src="../assets/png/enews-group.png" />
        </div>
        <div class="col-md-6 col-12">
          <div class="form-card text-start">
            <h1 :hidden="!deadLock" class="text-danger text-center mb-4">
            <svg
              class="bi flex-shrink-0 me-2"
              width="24"
              height="24"
              role="img"
              aria-label="Warning:"
            >
              <use xlink:href="#exclamation-triangle-fill" />
            </svg>
            คำเตือน! ท่านกำลังยกเลิกการรับข่าวสาร <br>ThaiPBS Catch up
            </h1>
            <div class="h2-bold" :hidden="!this.type[0] || !this.type[1] || !this.type[2]">
              เลือกจดหมายข่าวที่ต้องการยกเลิก
            </div>
            <small
              id="inputRequired0"
              class="text-danger"
              :hidden="!inputRequired[0]"
            >
              กรุณาเลือกยกเลิกข่าวสารอย่างน้อย 1 รายการ
            </small>
            <div class="form-check-group">
              <div
                :hidden="!this.type[0]"
                class="form-check mt-3 checkbox checkbox-danger error-checkbox"
              >
                <input
                  v-model="this.type2[0]"
                  v-on:change="updateCheckboxes(true)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault0"
                  :disabled="!this.ready"
                />
                <label class="form-check-label" for="flexCheckDefault0">
                  <strong
                    >Thai PBS Daily News Digest (ทุกวัน เวลา 15.00 น.)</strong
                  >
                  <br />
                  <span class="text-muted"
                    >คัดข่าวเด่นและสถานการณ์สำคัญในรอบวัน
                    ให้คุณไม่พลาดทั้งในไทยและทั่วโลก</span
                  >
                  <br />
                  <span class="text-muted"
                    >(
                    <a
                      href="#"
                      v-on:click="alertPreview(this.previews[0])"
                      type="button"
                      >ดูตัวอย่างจดหมายข่าว</a
                    >
                    )</span
                  >
                </label>
              </div>
              <div
                :hidden="!this.type[1]"
                class="form-check mt-3 checkbox checkbox-danger error-checkbox"
              >
                <input
                  v-model="this.type2[1]"
                  v-on:change="updateCheckboxes(true)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault1"
                  :disabled="isLoading || deadLock"
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  <strong>Thai PBS Catch up (1 ครั้ง / เดือน)</strong>
                  <br />
                  <span class="text-muted"
                    >คัดสรรคลิปดี ให้ชมฟรีกับวิดีโอยอดนิยมประจำเดือน</span
                  >
                  <br />
                  <span class="text-muted"
                    >(
                    <a
                      href="#"
                      v-on:click="alertPreview(this.previews[1])"
                      type="button"
                      >ดูตัวอย่างจดหมายข่าว</a
                    >
                    )</span
                  >
                </label>
              </div>
              <div
                :hidden="!this.type[2]"
                class="form-check mt-3 checkbox checkbox-danger error-checkbox"
              >
                <input
                  v-model="this.type2[2]"
                  v-on:change="updateCheckboxes(true)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault2"
                  :disabled="isLoading"
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  <strong>Thai PBS Highlight (1 ครั้ง / เดือน)</strong>
                  <br />
                  <span class="text-muted"
                    >แจ้งล่วงหน้ากับรายการเด่น กิจกรรมและแคมเปญดี ๆ เพื่อแฟน ๆ
                    ไทยพีบีเอส</span
                  >
                  <br />
                  <span class="text-muted"
                    >(
                    <a
                      href="#"
                      v-on:click="alertPreview(this.previews[2])"
                      type="button"
                      >ดูตัวอย่างจดหมายข่าว</a
                    >
                    )</span
                  >
                </label>
              </div>
            </div>
            <div class="h2-bold mt-4">ตรวจสอบอีเมลสมาชิกของท่าน</div>
            <div class="input-group mb-2">
              <label class="w-100">
                อีเมล <strong class="text-danger">*</strong>
                <input
                  type="email"
                  class="form-control"
                  style="font-size: 24px"
                  aria-label="กรอก Email ของท่าน"
                  v-model="this.email2"
                  v-on:keyup="triggerSearch"
                  :disabled="deadLock"
                />
              </label>
              <small
                id="inputRequired1"
                class="text-danger"
                :hidden="!inputRequired[1]"
              >
                กรุณากรอกอีเมลของท่าน
              </small>
              <small
                id="invalidEmail"
                class="text-danger"
                :hidden="!invalidEmail"
              >
                รูปแบบอีเมลของท่านไม่ถูกต้อง
              </small>
            </div>

            <div class="mt-4">
              <div id="headerReason" class="h2-bold">
                ท่านช่วยบอกเหตุผลกับทางเราหน่อยได้ไหม
                เพื่อเราจะได้ปรับปรุงและพัฒนาต่อไป
              </div>
               <small
                id="inputRequired2"
                class="text-danger"
                :hidden="!inputRequired[2]"
              >
                โปรดระบุเหตุผลก่อนดำเนินการต่อ
              </small>
              <div class="form-check mt-3">
                <input
                  class="form-check-input input-danger"
                  type="radio"
                  name="reason"
                  id="reason1"
                  value="ฉันไม่ต้องการรับจดหมายข่าวนี้แล้ว"
                  v-model="this.reason"
                  @change="updateRadio"
                  :disabled="isLoading"
                />
                <label class="form-check-label text-24" for="reason1">
                  ฉันไม่ต้องการรับจดหมายข่าวนี้แล้ว
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="reason2"
                  value="ฉันไม่เคยสมัครรับจดหมายข่าวนี้มาก่อน"
                  v-model="this.reason"
                  @change="updateRadio"
                />
                <label class="form-check-label text-24" for="reason2">
                  ฉันไม่เคยสมัครรับจดหมายข่าวนี้มาก่อน
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="reason3"
                  value="อีเมลที่ได้รับไม่ตรงตามความต้องการของฉัน"
                  v-model="this.reason"
                  @change="updateRadio"
                  :disabled="isLoading"
                />
                <label class="form-check-label text-24" for="reason3">
                  อีเมลที่ได้รับไม่ตรงตามความต้องการของฉัน
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="reason4"
                  value="other"
                  v-model="this.reason"
                  @change="updateRadio"
                  :disabled="isLoading"
                />
                <label class="form-check-label text-24" for="reason4">
                  อี่นๆ
                </label>
              </div>

              <div :hidden="!isOther" class="h2-bold mt-4">รบกวนแจ้งเหตุผลให้เราทราบ</div>
              <div :hidden="!isOther" class="input-group">
                <textarea
                  class="form-control"
                  style="font-size: 22px;"
                  aria-label="With textarea"
                  v-model="this.remark"
                  :disabled="isLoading"
                ></textarea>
              </div>
            </div>

            <div class="mt-4 text-center">
              <button
                :hidden="!isLoading || !ready"
                class="btn btn-lg btn-danger"
                type="button"
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                กำลังดำเนินการ...
              </button>
              <button
                :hidden="isLoading || !ready"
                v-on:click="submitForm"
                type="button"
                class="btn btn-danger"
                style="width: 200px; font-size: 24px"
              >
                ยกเลิกการรับจดหมายข่าว
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import AlertSuccess from "@/components/AlertSuccess.vue";
import AlertPreview from "@/components/AlertPreview.vue";
import AlertSubscribe from "@/components/AlertSubscribe.vue";
import AlertCondolence from "@/components/AlertCondolence.vue";
import sendy from "../assets/js/sendy";

export default {
  name: "UnsubscribeC",
  components: {
    Alert,
    AlertSuccess,
    AlertPreview,
    AlertSubscribe,
    AlertCondolence,
  },
  data() {
    return {
      ready: false,
      title: null,
      msg: null,
      all: true,
      invalidEmail: false,
      inputRequired: [false, false, false, false],
      type: [false, false, false],
      type2: [false, false, false],
      isLoading: false,
      email: this.$route.query.email,
      method: this.$route.params.method,
      email2: "",
      name: null,
      agreeTerms: false,
      reason: null,
      remark: null,
      isOther: false,
      deadLock: false,
      previews: [
        {
          title: "ตัวอย่าง - Thai PBS Daily News Digest (ทุกวัน เวลา 15.00 น.)",
          src: 2,
        },
        {
          title: "ตัวอย่าง - Thai PBS Catch up (1 ครั้ง / เดือน)",
          src: 1,
        },
        {
          title: "ตัวอย่าง - Thai PBS Highlight (1 ครั้ง / เดือน)",
          src: 0,
        },
      ],
    };
  },
  mounted: function () {
    this.init();
    if(this.method == 'catchup'){
      this.deadLock = true
      window.location.href = "#headerReason"
    }
  },
  constructor: function () {},
  methods: {
    init: function () {
      this.email2 = this.email;
      if (this.email) {
        sendy
          .searchSubscriber(this.email)
          .then((success) => {
            if (success == sendy.response_code.NOT_SUBSCRIBER) {
              this.alertSubscribe();
            } else {
              //LOOKS LIKE YOU ARE NOT MEMBER YET, SUBSCRIBE NOW!
              this.ready = true;
              console.log(success);

              if(this.deadLock){
              this.type[0] = false;
              this.type[1] = true;
              this.type[2] = false;
              this.type2[0] = false;
              this.type2[1] = true;
              this.type2[2] = false;
              }else{
              this.type[0] = success[0];
              this.type[1] = success[1];
              this.type[2] = success[2];
              }
            }
          })
          .catch((err) => {
            console.log(err);
            //this.alertError('เกิดข้อผิดพลาด', 'การเชื่อมต่อเกิดการขัดข้อง กรุณาลองใหม่อีกครั้ง');
            this.ready = false;
          });
      } else {
        console.log("NOT READY");
        this.ready = false;
      }
    },
    submitForm: async function () {
      //this.isLoading = true;
      this.invalidEmail = false;
      this.isLoading = false;
      this.inputRequired.forEach((v, i) => {
        this.inputRequired[i] = false;
      });

      console.log(this.email, this.name, this.type);
      this.validateForm();

      //this.isLoading = false;
    },
    updateCheckboxes: function (isType) {
      if (isType) {
        if (this.type2[0] && this.type2[1] && this.type2[2]) {
          this.all = true;
        }

        if (!this.type2[0] && !this.type2[1] && !this.type2[2]) {
          this.all = false;
        }
      } else {
        if (this.all) {
          this.type2[0] = true;
          this.type2[1] = true;
          this.type2[2] = true;
        } else {
          this.type2[0] = false;
          this.type2[1] = false;
          this.type2[2] = false;
        }
      }
    },
    validateForm: function () {
      if (this.email2) {
        if (this.validateEmail(this.email2)) {
          this.invalidEmail = false;
          //CHECK AT LEAST ONE CHECKBOX MUST BE CROSSED
          if (!this.type2[0] && !this.type2[1] && !this.type2[2]) {
            this.isLoading = false;
            this.inputRequired[0] = true;
            window.location.href = "#inputRequired0";
          } else {
            //REASON MUST NOT BE INVALID
            if (this.reason) {
              this.isLoading = true;
              //UNSUBSCRIBE AS CROSS IN THE LIST
              sendy
                .unsubscribeEach(
                  this.email,
                  this.type2,
                  this.reason,
                  this.remark
                )
                .then(() => {
                  //ALERTSUCCESS
                  this.isLoading = false;
                  this.type = this.type2;
                  this.alertCondolence();
                })
                .catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                  this.alertError(
                    "เกิดข้อผิดพลาด!",
                    "การเชื่อมต่อขัดข้อง กรุณาลองใหม่อีกครั้ง"
                  );
                });
            } else {
              this.isLoading = false;
              this.inputRequired[2] = true;
              window.location.href = "#inputRequired2";
            }
          }
        } else {
          //INVALID EMAIL
          this.isLoading = false;
          this.invalidEmail = true;
        }
      } else {
        this.isLoading = false;
        this.inputRequired[1] = true;
        window.location.href = "#inputRequired1";
      }
    },
    validateEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    triggerSearch: function () {
      this.invalidEmail = false;
      this.email = this.email2;

      if (this.validateEmail(this.email)) {
        console.log(this.email);
        sendy
          .searchSubscriber(this.email)
          .then((success) => {
            if (success == sendy.response_code.NOT_SUBSCRIBER) {
              ///this.alertSubscribe();
              this.ready = false;
              this.type[0] = false;
              this.type[1] = false;
              this.type[2] = false;
            } else {
              //LOOKS LIKE YOU ARE NOT MEMBER YET, SUBSCRIBE NOW!
              this.ready = true;
              this.type[0] = success[0];
              this.type[1] = success[1];
              this.type[2] = success[2];
            }
          })
          .catch((err) => {
            console.log(err);
            //this.alertError('เกิดข้อผิดพลาด', 'การเชื่อมต่อเกิดการขัดข้อง กรุณาลองใหม่อีกครั้ง');
            this.ready = false;
          });
      } else {
        this.invalidEmail = true;
        this.ready = false;
      }
    },
    updateRadio: function () {
      if (this.reason === "other") {
        this.isOther = true;
        console.log(this.reason);
      } else {
        this.isOther = false;
      }
    },
    checkEmailFormat: function(){
      if(this.validateEmail(this.email)){
        this.invalidEmail = false
      }else{
        this.invalidEmail = true
      }
    },
    alertError: function (title, msg) {
      this.title = title;
      this.msg = msg;
      document.querySelector("#btnAlertError").click();
    },
    alertSuccess: function (title, msg) {
      this.title = title;
      this.msg = msg;
      document.querySelector("#btnAlertSuccess").click();
    },
    alertEditSubscribe: function () {
      document.querySelector("#btnAlertEditSubscribe").click();
    },
    alertPreview: function (prv) {
      this.title = prv.title;
      this.src = prv.src;
      document.querySelector("#btnAlertPreview").click();
    },
    alertSubscribe: function () {
      document.querySelector("#btnAlertSubscribe").click();
    },
    alertUnsubscribe: function () {
      document.querySelector("#btnAlertUnsubscribe").click();
    },
    alertCondolence: function () {
      document.querySelector("#btnAlertCondolence").click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #fe5000;
}

.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 21px;
  height: 21px;
  left: 0;
  margin-left: -20px;
  margin-top: 6px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0;
  top: 0;
  margin-left: -20px;
  margin-top: 6px;
  padding-left: 0;
  padding-top: 0;
  font-size: 11px;
  color: #555555;
  line-height: 19px;
  text-align: center;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after {
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 13px;
  content: "x";
}
.checkbox.error-checkbox input[type="checkbox"]:checked + label::after {
  content: "x";
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
</style>
