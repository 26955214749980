const axios = require('axios').default;

const sendy = {
    base_url: 'https://newsletter.thaipbs.or.th',
    api_key: '00f96Yw7K7SKs0FMQ4UB',
    lists: [
        'zVvUlD892eqsLKTnr8r2DeSQ', //Digest
        'CSlnKxuoA8923lCV8W4EiYTA', //Catchup
        'fxtnHRm4cUwmTEsoZEanqA' //Hilight
    ],
    response_code: {
        NOT_SUBSCRIBER: 'NOT SUBSCRIBER',
        IS_SUBSCRIBER: 'IS SUBSCRIBER',
        SUCCESS: 'SUCCESS'
    },
    test: function () {
        console.log('SENDY CONNECTED')
    },
    searchSubscriber: function (email) {
        return new Promise((resolve, reject) => {
            try {
                let responseDigest = null
                let responseCatchup = null
                let responseHilight = null
                this.checkstatus(email, this.lists[0]).then(response => {
                    responseDigest = response
                    this.checkstatus(email, this.lists[1]).then(response => {
                        responseCatchup = response
                        this.checkstatus(email, this.lists[2]).then(response => {
                            responseHilight = response
                            let list = [false, false, false]
                            if (responseDigest.data === 'Unsubscribed' && responseCatchup.data === 'Unsubscribed' && responseHilight.data === 'Unsubscribed') {
                                //NOT SUBSCRIBER
                                resolve(this.response_code.NOT_SUBSCRIBER)
                            } else if (responseDigest === 'Email does not exist in list' && responseCatchup === 'Email does not exist in list' && responseHilight === 'Email does not exist in list') {
                                //NOT SUBSCRIBER
                                resolve(this.response_code.NOT_SUBSCRIBER)
                            } else {
                                //SUBSCRIBER
                                console.log(responseDigest, responseCatchup, responseHilight)
                                if (responseDigest.data === 'Subscribed') list[0] = true;
                                if (responseCatchup.data === 'Subscribed') list[1] = true;
                                if (responseHilight.data === 'Subscribed') list[2] = true;

                                console.log(list)

                                if (list[0] == false && list[1] == false && list[2] == false) {
                                    resolve(this.response_code.NOT_SUBSCRIBER)
                                } else {
                                    resolve(list)
                                }
                            }
                        }).catch(err => {
                            reject(err)
                        })
                    }).catch(err => {
                        reject(err)
                    })
                }).catch(err => {
                    reject(err)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    subscribeEach: function (name, email, lists, referrer, reason, remark) {
        return new Promise((resolve, reject) => {
            try {
                if (lists[0]) {
                    this.subscribe(name, email, this.lists[0], referrer, reason, remark).then((v) => {
                        console.log(v)
                        if (lists[1]) {
                            this.subscribe(name, email, this.lists[1], referrer, reason, remark).then((v) => {
                                console.log(v)
                                if (lists[2]) {
                                    this.subscribe(name, email, this.lists[2], referrer, reason, remark).then((v) => {
                                        console.log(v)
                                        resolve(this.response_code.SUCCESS)
                                    }).catch(err => {
                                        reject(err)
                                    })
                                } else {
                                    resolve(this.response_code.SUCCESS)
                                }
                            }).catch(err => {
                                reject(err)
                            })
                        } else {
                            if (lists[2]) {
                                this.subscribe(name, email, this.lists[2], referrer, reason, remark).then(() => {
                                    resolve(this.response_code.SUCCESS)
                                }).catch(err => {
                                    reject(err)
                                })
                            } else {
                                resolve(this.response_code.SUCCESS)
                            }
                        }
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    if (lists[1]) {
                        this.subscribe(name, email, this.lists[1], referrer, reason, remark).then(() => {
                            if (lists[2]) {
                                this.subscribe(name, email, this.lists[2], referrer, reason, remark).then(() => {
                                    resolve(this.response_code.SUCCESS)
                                }).catch(err => {
                                    reject(err)
                                })
                            } else {
                                resolve(this.response_code.SUCCESS)
                            }
                        }).catch(err => {
                            reject(err)
                        })
                    } else {
                        if (lists[2]) {
                            this.subscribe(name, email, this.lists[2], referrer, reason, remark).then(() => {
                                resolve(this.response_code.SUCCESS)
                            }).catch(err => {
                                reject(err)
                            })
                        } else {
                            resolve(this.response_code.SUCCESS)
                        }
                    }
                }
            } catch (error) {
                reject(error)
            }
        })
    },
    unsubscribeEach: function (email, lists, reason, remark) {
        return new Promise((resolve, reject) => {
            try {
                if (lists[0]) {
                    this.unsubscribe(email, this.lists[0], reason, remark).then((v) => {
                        console.log('V', v)
                        if (lists[1]) {
                            this.unsubscribe(email, this.lists[1], reason, remark).then((v) => {
                                console.log('V', v)
                                if (lists[2]) {
                                    this.unsubscribe(email, this.lists[2], reason, remark).then((v) => {
                                        console.log('V', v)
                                        resolve(this.response_code.SUCCESS)
                                    }).catch(err => {
                                        reject(err)
                                    })
                                } else {
                                    resolve(this.response_code.SUCCESS)
                                }
                            }).catch(err => {
                                reject(err)
                            })
                        } else {
                            if (lists[2]) {
                                this.unsubscribe(email, this.lists[2], reason, remark).then(() => {
                                    resolve(this.response_code.SUCCESS)
                                }).catch(err => {
                                    reject(err)
                                })
                            } else {
                                resolve(this.response_code.SUCCESS)
                            }
                        }
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    if (lists[1]) {
                        this.unsubscribe(email, this.lists[1], reason, remark).then(() => {
                            if (lists[2]) {
                                this.unsubscribe(email, this.lists[2], reason, remark).then(() => {
                                    resolve(this.response_code.SUCCESS)
                                }).catch(err => {
                                    reject(err)
                                })
                            } else {
                                resolve(this.response_code.SUCCESS)
                            }
                        }).catch(err => {
                            reject(err)
                        })
                    } else {
                        if (lists[2]) {
                            this.unsubscribe(email, this.lists[2], reason, remark).then(() => {
                                resolve(this.response_code.SUCCESS)
                            }).catch(err => {
                                reject(err)
                            })
                        } else {
                            resolve(this.response_code.SUCCESS)
                        }
                    }
                }
            } catch (error) {
                reject(error)
            }
        })
    },
    deleteSubscriberEach: function (email, lists) {
        return new Promise((resolve, reject) => {
            try {
                if (lists[0]) {
                    this.deleteSubscriber(email, this.lists[0]).then(() => {
                        if (lists[1]) {
                            this.deleteSubscriber(email, this.lists[1]).then(() => {
                                if (lists[2]) {
                                    this.deleteSubscriber(email, this.lists[2]).then(() => {
                                        resolve(this.response_code.SUCCESS)
                                    }).catch(err => {
                                        reject(err)
                                    })
                                } else {
                                    resolve(this.response_code.SUCCESS)
                                }
                            }).catch(err => {
                                reject(err)
                            })
                        } else {
                            if (lists[2]) {
                                this.deleteSubscriber(email, this.lists[2]).then(() => {
                                    resolve(this.response_code.SUCCESS)
                                }).catch(err => {
                                    reject(err)
                                })
                            } else {
                                resolve(this.response_code.SUCCESS)
                            }
                        }
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    if (lists[1]) {
                        this.deleteSubscriber(email, this.lists[1]).then(() => {
                            if (lists[2]) {
                                this.deleteSubscriber(email, this.lists[2]).then(() => {
                                    resolve(this.response_code.SUCCESS)
                                }).catch(err => {
                                    reject(err)
                                })
                            } else {
                                resolve(this.response_code.SUCCESS)
                            }
                        }).catch(err => {
                            reject(err)
                        })
                    } else {
                        if (lists[2]) {
                            this.deleteSubscriber(email, this.lists[2]).then(() => {
                                resolve(this.response_code.SUCCESS)
                            }).catch(err => {
                                reject(err)
                            })
                        } else {
                            resolve(this.response_code.SUCCESS)
                        }
                    }
                }
            } catch (error) {
                reject(error)
            }
        })
    },
    subscribe: function (name, email, list_id, referrer, reason, remark) {
        return new Promise((resolve, reject) => {
            try {
                const params = new URLSearchParams()
                params.append('api_key', this.api_key)
                params.append('reason', reason)
                params.append('remark', remark)
                params.append('country', 'TH')
                params.append('name', name)
                params.append('email', email)
                params.append('list', list_id)
                params.append('referrer', referrer)
                params.append('boolean', true)

                axios.post(this.base_url + '/subscribe', params, {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                    },
                }).then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    unsubscribe: function (email, list_id, reason, remark) {
        return new Promise((resolve, reject) => {
            try {
                this.deleteSubscriber(email, list_id).then(() => {
                    this.subscribe('', email, list_id, '', reason, remark).then(() => {
                        const params = new URLSearchParams()
                        params.append('email', email)
                        params.append('list', list_id)
                        params.append('boolean', true)

                        axios.post(this.base_url + '/unsubscribe', params, {
                            headers: { 'content-type': 'application/x-www-form-urlencoded' }
                        }).then(response => {
                            resolve(response)
                        }).catch(err => {
                            reject(err)
                        })
                    }).catch(err => {
                        reject(err)
                    })
                }).catch(err => {
                    reject(err)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    checkstatus: function (email, list_id) {
        return new Promise((resolve, reject) => {
            try {
                const params = new URLSearchParams()
                params.append('api_key', this.api_key)
                params.append('email', email)
                params.append('list_id', list_id)

                axios.post(this.base_url + '/api/subscribers/subscription-status.php', params, {
                    headers: { 'content-type': 'application/x-www-form-urlencoded' }
                }).then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            } catch (error) {
                reject(error)
            }
        })
    },
    deleteSubscriber: function (email, list_id) {
        return new Promise((resolve, reject) => {
            try {
                const params = new URLSearchParams()
                params.append('api_key', this.api_key)
                params.append('email', email)
                params.append('list_id', list_id)

                axios.post(this.base_url + '/api/subscribers/delete.php', params, {
                    headers: { 'content-type': 'application/x-www-form-urlencoded' }
                }).then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default sendy;